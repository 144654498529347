<script setup>
import { useForm, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    member: Object,
    functions: Object,
    status: Object,
    allRoles: Array,
    roles: Array,
});

const form = useForm({
    function: props.member.function,
    status: props.member.status,
    defaultFunction: props.roles.find((role) => role.default)?.department_function_id || null,
    roles: props.roles.map((role) => role.department_function_id),
});

const toggleFunction = (functionId) => {
    if (form.roles.includes(functionId)) {
        form.roles = form.roles.filter((id) => id !== functionId);
        if (form.defaultFunction === functionId) {
            form.defaultFunction = null;
        }
    } else {
        form.roles.push(functionId);
    }
};

const setDefaultFunction = (functionId) => {
    if (form.roles.includes(functionId)) {
        form.defaultFunction = form.defaultFunction === functionId ? null : functionId;
    }
};

const update = () => form.put(`/dashboard/departments/members/${props.member.id}`);
</script>

<template>
    <Dashboard :page-title="`Editar ${member.member.clean_name}`">
        <Breadcrumb
            :current-page-name="`Editar ${member.member.clean_name}`"
            :link-list="[
                {
                    previous_page_url: `departments/${member.department.id}`,
                    previous_page_name: `${member.department.name}`,
                },
            ]"
        />

        <div id="edit-department-member">
            <h4 class="my-3">Editar {{ member.member.clean_name }}</h4>

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-6">
                    <label for="function" class="form-label">Hierarquia do membro</label>
                    <select
                        id="function"
                        v-model="form.function"
                        class="form-select form-select"
                        aria-label="Small select example"
                    >
                        <option v-for="(item, index) in functions" :key="index" :value="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="status" class="form-label">Status do membro</label>
                    <select
                        id="status"
                        v-model="form.status"
                        class="form-select form-select"
                        aria-label="Small select example"
                    >
                        <option v-for="(item, index) in status" :key="index" :value="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <hr class="mb-0" />

                <div class="col-lg-12">
                    <legend>Funções desse membro</legend>
                    <ul class="list-group">
                        <li
                            class="list-group-item d-flex justify-content-between align-items-center p-3"
                            v-for="(item, index) in allRoles"
                            :key="index"
                        >
                            <div class="d-flex align-items-center">
                                <input
                                    class="form-check-input me-1 mt-0"
                                    type="checkbox"
                                    :id="`role-${index}`"
                                    :value="item.id"
                                    :checked="form.roles.includes(item.id)"
                                    @change="toggleFunction(item.id)"
                                />
                                <label
                                    class="form-check-label ms-2"
                                    :for="`role-${index}`"
                                    style="cursor: pointer"
                                >
                                    {{ item.name }}
                                </label>
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    class="btn-check"
                                    :id="`default-role-${index}`"
                                    autocomplete="off"
                                    :checked="form.defaultFunction === item.id"
                                    @change="setDefaultFunction(item.id)"
                                />
                                <label
                                    class="btn btn-outline-success"
                                    :for="`default-role-${index}`"
                                >
                                    Definir como padrão
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </Form>
        </div>
    </Dashboard>
</template>
