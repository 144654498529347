<script setup>
import { reactive, onMounted, computed } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PieChart from '@/Pages/Dashboard/Graphics/PieChart.vue';
import BarChart from '@/Pages/Dashboard/Graphics/BarChart.vue';

import EventCard from '@/Pages/Dashboard/Pages/Events/Components/EventCard.vue';
import WaterBaptismCard from '../Pages/WaterBaptisms/Components/WaterBaptismCard.vue';

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

import DepartmentCard from '../Pages/Departments/Components/DepartmentCard.vue';

import useDateFormat from '@/Pages/Dashboard/Composables/useDateFormat.vue';

const { simplifyDate } = useDateFormat();

const props = defineProps({
    visitorsLastThirtyDays: Number,
    newBirthsLastThirtyDays: Number,
    officialMembers: Number,
    totalFamilies: Number,
    allVisitors: Object,
    allCensus: Object,
    lastVisitors: Object,
    lastNewBirths: Object,
    waterBaptism: Object,
    lastEvents: Object,
    birthdaysMonth: Object,
    newBirthsPerMonth: Object,
    censusByMonth: Object,
    newBirthsPerYear: Object,
    rateMembersServing: Object,
    averageBySchedule: Object,
    month: String,
    growthRate: Number,
    eventsYear: Number,
    requestsPendingPastor: Object,
    scales: Object,
    departments: Object,
});

const goToNewBirthPage = (memberId) => router.get(`/dashboard/new-births/${memberId}`);
const goToVisitorPage = (memberId) => router.get(`/dashboard/visitors/${memberId}`);
const goToMemberPage = (memberId) => router.get(`/dashboard/members/${memberId}`);
const goToMusicPage = (musicId) => router.get(`/dashboard/musics/${musicId}`);

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${day}/${month}`;
};

const clearQueryParams = () => router.replace('/dashboard');

const seacher = reactive({
    birthday_month: props.month,
    new_birth_month: props.month,
    censu_month: props.month,
});

const search = () => {
    router.visit(`/dashboard`, {
        method: 'get',
        data: { seacher },
        only: ['birthdaysMonth', 'newBirthsPerMonth', 'averageBySchedule'],
        preserveState: true,
        preserveScroll: true,
    });
};

const last12Months = computed(() => {
    const months = [];
    const currentDate = new Date();

    for (let i = 0; i < 12; i++) {
        const monthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
        const month = monthDate
            .toLocaleString('pt-BR', { month: 'short' })
            .toUpperCase()
            .replace('.', ' -');
        const year = monthDate.getFullYear();
        const formattedMonthYear = `${month} ${year}`;
        const value = `${(monthDate.getMonth() + 1).toString().padStart(2, '0')}/${year}`;

        months.push({ name: formattedMonthYear, value });
    }

    return months;
});

onMounted(() => {
    clearQueryParams();

    const firstMonthValue = last12Months.value[0]?.value;

    if (firstMonthValue) {
        seacher.new_birth_month = firstMonthValue;
        seacher.censu_month = firstMonthValue;
    }
});

const goDepartmentRequest = (id) => router.get(`/dashboard/departments/entry-requests/${id}`);
</script>

<template>
    <Dashboard page-title="Visão geral">
        <div class="mt-2 mx-0" id="home">
            <h3 class="font-bold mb-3">Visão geral</h3>
            <div class="row" id="main-cards">
                <div class="col-md-3 col-6">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <h5 class="card-title text-secondary font-bold">Crescimento</h5>
                            <h4 class="font-bold mb-lg-3">
                                {{ growthRate }}%
                                <span class="fs-12">nesse ano</span>
                            </h4>
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-8">
                                    <span class="font-semibold fs-12"
                                        >{{ officialMembers }} membros</span
                                    >
                                </div>
                                <div class="col-lg-4 text-end mt-3 mt-md-0">
                                    <span
                                        class="px-3 py-2 bg-primary bg-opacity-50 shadow-sm rounded-2"
                                    >
                                        <font-awesome-icon :icon="['fas', 'chart-line']"
                                    /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 col-6">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <h5 class="card-title text-secondary font-bold">Visitantes</h5>
                            <h4 class="font-bold mb-lg-3">
                                {{ visitorsLastThirtyDays }}
                                <span class="fs-12"> - últimos 30d</span>
                            </h4>
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-8">
                                    <Link class="font-semibold fs-12" href="/dashboard/visitors"
                                        >Ver visitantes</Link
                                    >
                                </div>
                                <div class="col-lg-4 text-end mt-3 mt-md-0">
                                    <span
                                        class="px-3 py-2 bg-warning bg-opacity-50 rounded-2 shadow-sm"
                                    >
                                        <font-awesome-icon :icon="['fas', 'person-shelter']"
                                    /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 col-6 mt-3 mt-sm-2 mt-md-0">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <h5 class="card-title text-secondary font-bold">Famílias</h5>
                            <h4 class="font-bold mb-lg-3">
                                {{ totalFamilies }} <span class="fs-12">criadas</span>
                            </h4>
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-8">
                                    <Link class="font-semibold fs-12" href="/dashboard/families"
                                        >Ver famílias</Link
                                    >
                                </div>
                                <div class="col-lg-4 text-end mt-3 mt-md-0">
                                    <span
                                        class="px-3 py-2 bg-success bg-opacity-50 shadow-sm rounded-2"
                                    >
                                        <font-awesome-icon :icon="['fas', 'people-roof']"
                                    /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 col-6 mt-3 mt-sm-2 mt-md-0">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <h5 class="card-title text-secondary font-bold">Eventos</h5>
                            <h4 class="font-bold mb-lg-3">
                                {{ eventsYear }} <span class="fs-12">nesse ano</span>
                            </h4>
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-8">
                                    <Link class="font-semibold fs-12" href="/dashboard/events"
                                        >Ver eventos</Link
                                    >
                                </div>
                                <div class="col-lg-4 text-end mt-3 mt-md-0">
                                    <span
                                        class="px-3 py-2 bg-danger bg-opacity-75 shadow-sm rounded-2"
                                    >
                                        <font-awesome-icon
                                            class="text-white"
                                            :icon="['fas', 'calendar']"
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <div class="row d-flex justify-content-between align-items-center">
                <div class="col-sm-6">
                    <h4 class="font-bold font-bold mb-0">Escalas</h4>
                    <span class="text-secondary font-semibold fs-12 mb-3"
                        >Confira a sua escala desta semana</span
                    >
                </div>
                <div class="col-sm-6 text-end mt-3 mt-sm-0">
                    <a
                        href="/dashboard/members/monthly-scale/export"
                        class="btn btn-danger font-bold fs-12"
                        :class="{ disabled: !scales.length }"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />Escala completa
                    </a>
                </div>
            </div>

            <template v-if="scales.length">
                <div class="mt-3" v-for="(event, index) in scales" :key="index">
                    <div class="row">
                        <div class="col-lg-4 d-none d-md-block">
                            <EventCard :event="event.event" :forTable="true" />
                        </div>
                        <div class="col-lg-8">
                            <div
                                v-for="(
                                    department_scale, indexDepartmentScale
                                ) in event.department_scales"
                                :key="indexDepartmentScale"
                            >
                                <div class="card">
                                    <div class="card-header fs-14 p-3 font-bold">
                                        {{ event.event.schedule.name }} -
                                        {{ simplifyDate(event.event.start_time) }}
                                    </div>
                                    <div class="card-body" style="overflow: auto; height: 320px">
                                        <div v-if="department_scale.child_lessons?.length">
                                            <div
                                                class="d-flex justify-content-between align-items-center"
                                            >
                                                <div class="font-bold">Acesso rápido</div>
                                                <div>
                                                    <a
                                                        v-if="
                                                            department_scale.child_lessons[0]
                                                                .support_material
                                                        "
                                                        :href="`/storage/${department_scale.child_lessons[0].support_material}`"
                                                        download
                                                        class="btn btn-danger font-bold fs-12"
                                                    >
                                                        <font-awesome-icon
                                                            class="me-2"
                                                            :icon="['fa', 'file-pdf']"
                                                        />Material de apoio
                                                    </a>

                                                    <Link
                                                        class="btn btn-primary ms-2 font-bold fs-12"
                                                        :href="`/dashboard/child-department-classes/${department_scale.child_lessons[0].child_department_class_id}/lessons/${department_scale.child_lessons[0].id}`"
                                                    >
                                                        Aula
                                                    </Link>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                        <div
                                            v-if="!department_scale.musical_planning?.id"
                                            class="alert mx-0 font-semibold fs-12 alert-primary"
                                            role="alert"
                                        >
                                            Servindo - {{ department_scale.department.name }}
                                        </div>
                                        <hr v-if="!department_scale.musical_planning?.id" />

                                        <div
                                            class="accordion"
                                            id="accordionExample"
                                            v-if="department_scale.musical_planning?.id"
                                        >
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button
                                                        class="accordion-button collapsed font-bold"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseOne"
                                                        aria-expanded="false"
                                                        aria-controls="collapseOne"
                                                    >
                                                        <font-awesome-icon
                                                            class="text-primary me-3"
                                                            :icon="['fas', 'music']"
                                                        />
                                                        Músicas e Paletas de cores
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseOne"
                                                    class="accordion-collapse collapse"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div class="accordion-body">
                                                        <template
                                                            v-if="
                                                                department_scale.musical_planning
                                                                    .colors.length
                                                            "
                                                        >
                                                            <h5 class="font-bold font-bold mb-0">
                                                                Paleta de cores
                                                            </h5>
                                                            <span
                                                                class="text-secondary font-semibold fs-12 mb-4"
                                                            >
                                                                Que serão usadas pela equipe
                                                            </span>
                                                            <div
                                                                class="d-flex justify-content-center mt-3"
                                                            >
                                                                <div
                                                                    class="text-center d-flex flex-column align-items-center mx-2"
                                                                    v-for="(
                                                                        item, index
                                                                    ) in department_scale
                                                                        .musical_planning.colors"
                                                                    :key="index"
                                                                >
                                                                    <div
                                                                        :style="{
                                                                            width: '50px',
                                                                            height: '50px',
                                                                            backgroundColor: `${item.color}`,
                                                                            borderRadius: '50%',
                                                                        }"
                                                                        class="border border-3 border-info"
                                                                    ></div>
                                                                    <span
                                                                        class="mt-2 font-semibold"
                                                                        >{{ item.name }}</span
                                                                    >
                                                                </div>
                                                            </div>
                                                            <hr />
                                                        </template>

                                                        <template
                                                            v-if="
                                                                department_scale.musical_planning
                                                                    .musics.length
                                                            "
                                                        >
                                                            <h4 class="font-bold font-bold mb-0">
                                                                Músicas
                                                            </h4>
                                                            <span
                                                                class="text-secondary font-semibold fs-12 mb-3"
                                                                >Relacionadas a esse evento</span
                                                            >
                                                            <div
                                                                class="table-responsive search-form-card mt-3"
                                                            >
                                                                <table
                                                                    class="table m-0 align-middle table-hover"
                                                                    id="table-musics"
                                                                >
                                                                    <thead class="table-light">
                                                                        <tr>
                                                                            <th scope="col">#</th>
                                                                            <th scope="col">
                                                                                Nome
                                                                            </th>
                                                                            <th
                                                                                class="text-center"
                                                                                scope="col"
                                                                            >
                                                                                Youtube
                                                                            </th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr
                                                                            v-for="(
                                                                                music, musicIndex
                                                                            ) in department_scale
                                                                                .musical_planning
                                                                                .musics"
                                                                            :key="musicIndex"
                                                                            @click="
                                                                                goToMusicPage(
                                                                                    music.id
                                                                                )
                                                                            "
                                                                        >
                                                                            <td>
                                                                                {{ musicIndex + 1 }}
                                                                            </td>
                                                                            <td>
                                                                                <img
                                                                                    class="rounded-pill me-3"
                                                                                    height="35"
                                                                                    width="35"
                                                                                    :src="`/storage/${music.cover_photo}`"
                                                                                    alt=""
                                                                                />
                                                                                <span>
                                                                                    {{ music.name }}
                                                                                </span>
                                                                            </td>

                                                                            <td class="text-center">
                                                                                <a
                                                                                    target="_blank"
                                                                                    class="btn btn-link text-danger p-0 fs-3"
                                                                                    :class="{
                                                                                        disabled:
                                                                                            !music.youtube_link,
                                                                                    }"
                                                                                    :href="
                                                                                        music.youtube_link
                                                                                    "
                                                                                    data-bs-toggle="tooltip"
                                                                                    data-bs-placement="bottom"
                                                                                    data-bs-title="Assistir no Youtube"
                                                                                >
                                                                                    <font-awesome-icon
                                                                                        :icon="[
                                                                                            'fab',
                                                                                            'youtube',
                                                                                        ]"
                                                                                    />
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="border d-block p-3 rounded-3 my-2"
                                            v-for="(
                                                member, indexMember
                                            ) in department_scale.scale_members.filter(
                                                (m) => !m.replacement_id
                                            )"
                                            :key="indexMember"
                                            :class="{
                                                'bg-danger bg-opacity-25':
                                                    member.presence_status === 'ABSENT',
                                            }"
                                        >
                                            <Link
                                                :href="`/dashboard/members/${member.departmentMember.member.id}`"
                                                class="link-underline-light link-dark link-underline-opacity-0"
                                            >
                                                <div
                                                    class="d-flex justify-content-between align-items-center"
                                                >
                                                    <div>
                                                        <div class="d-flex align-items-center">
                                                            <img
                                                                class="rounded-circle me-3"
                                                                width="30"
                                                                :src="
                                                                    member.departmentMember.member
                                                                        .identification_photo
                                                                "
                                                                alt=""
                                                            />
                                                            <div>
                                                                <span class="font-bold fs-12">{{
                                                                    member.departmentMember.member
                                                                        .clean_name
                                                                }}</span>
                                                                <span
                                                                    v-if="
                                                                        member.departmentFunction
                                                                            ?.name
                                                                    "
                                                                    class="font-semibold fs-11 badge text-bg-primary ms-sm-3 p-2"
                                                                    >{{
                                                                        member.departmentFunction
                                                                            .name
                                                                    }}</span
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="member.departmentFunction?.photo">
                                                        <img
                                                            class="me-3"
                                                            width="30"
                                                            :src="member.departmentFunction.photo"
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            </Link>

                                            <span
                                                v-if="member.replacement_id"
                                                class="text-primary ms-2"
                                            >
                                                <font-awesome-icon
                                                    class="rotate-icon mx-3"
                                                    :icon="['fas', 'rotate']"
                                                />
                                                <span>
                                                    {{ member.replacement.clean_name }}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-else>
                <div class="alert mx-0 font-semibold fs-12 alert-primary mt-4" role="alert">
                    Você não foi escalado em nenhum evento desta semana
                </div>
            </template>

            <hr />

            <template v-if="departments.data?.length">
                <h4 class="font-bold font-bold mb-0">Departamentos</h4>
                <span class="text-secondary font-semibold fs-12 mb-3">Veja onde você serve</span>

                <div class="row mt-3">
                    <DepartmentCard :data="departments.data" />
                </div>
            </template>

            <div class="row d-block d-sm-none">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <div class="search-form-card overflow-auto shadow-sm" id="last-sales">
                                <div
                                    class="card-header d-flex align-items-center justify-content-between bg-white p-3"
                                >
                                    <span class="font-bold">Últimos visitantes</span>
                                    <Link
                                        class="btn btn-success fs-12 font-semibold"
                                        href="/dashboard/visitors"
                                        >Ver todos</Link
                                    >
                                </div>
                                <hr class="my-0" />
                                <div class="py-0">
                                    <div class="table-responsive" v-if="lastVisitors?.data.length">
                                        <table
                                            class="table align-middle table-hover"
                                            id="table-home-visitors"
                                        >
                                            <thead>
                                                <tr>
                                                    <th scope="col">Nome</th>
                                                    <th scope="col">Programação</th>
                                                    <th scope="col">Crente</th>
                                                    <th scope="col">Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(member, index) in lastVisitors.data"
                                                    :key="index"
                                                    @click="goToVisitorPage(member.id)"
                                                    :class="{
                                                        'table-warning': member.is_event_today,
                                                    }"
                                                >
                                                    <td>
                                                        {{ member.member.clean_name }}
                                                    </td>
                                                    <td>
                                                        <span v-if="member.schedule_name">{{
                                                            member.schedule_name
                                                        }}</span>

                                                        <span
                                                            v-else
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="top"
                                                            data-bs-title="Dado do SGI antigo"
                                                        >
                                                            <font-awesome-icon
                                                                :icon="['fas', 'minus']"
                                                            />
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <Badge
                                                            :name="
                                                                member.member.believer
                                                                    ? 'YES'
                                                                    : 'NOT'
                                                            "
                                                        />
                                                    </td>
                                                    <td>
                                                        <span v-if="member.is_event_today">
                                                            <span class="badge text-bg-primary p-2"
                                                                >Hoje</span
                                                            >
                                                        </span>
                                                        <span v-else>
                                                            {{ member.created_at }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div v-else class="text-center p-4">
                                        Nenhum visitante encontrado
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="search-form-card shadow-sm">
                            <div
                                class="card-header d-flex align-items-center bg-white p-3 border-bottom"
                            >
                                <span class="font-bold mb-2"
                                    >Conversões - {{ new Date().getFullYear() }}</span
                                >
                            </div>
                            <div class="card-body">
                                <BarChart
                                    :labels="newBirthsPerYear['labels']"
                                    :series="newBirthsPerYear['series']"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 mt-3 mt-sm-0">
                        <div class="search-form-card shadow-sm">
                            <div
                                class="card-header d-flex align-items-center justify-content-between bg-white p-3 border-bottom"
                            >
                                <span class="font-bold">Conversões</span>
                                <div>
                                    <select
                                        v-model="seacher.new_birth_month"
                                        class="form-select"
                                        aria-label="Selecione o mês"
                                        @change="search()"
                                    >
                                        <option
                                            v-for="month in last12Months"
                                            :key="month.value"
                                            :value="month.value"
                                        >
                                            {{ month.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="card-body">
                                <BarChart
                                    :labels="newBirthsPerMonth['labels']"
                                    :series="newBirthsPerMonth['series']"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 mt-3 mt-sm-0">
                        <div class="search-form-card shadow-sm">
                            <div
                                class="card-header d-flex align-items-center bg-white p-3 border-bottom"
                            >
                                <span class="font-bold mb-2">Obreiros Ativo</span>
                            </div>
                            <div class="card-body p-3 mb-3">
                                <PieChart
                                    :labels="rateMembersServing['labels']"
                                    :series="rateMembersServing['series']"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 mt-2" v-if="requestsPendingPastor.length">
                        <hr />
                        <h4 class="font-bold font-bold mb-0">Solicitações departamentos</h4>
                        <span class="text-secondary font-semibold fs-12"
                            >Aguardando sua aprovação</span
                        >
                        <div class="search-form-card mt-3">
                            <div class="table-responsive">
                                <table
                                    class="table m-0 align-middle table-hover"
                                    id="table-families"
                                >
                                    <thead class="table-light">
                                        <tr>
                                            <th class="text-center" scope="col">#</th>
                                            <th scope="col">Departamento</th>
                                            <th scope="col">Membro solicitante</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr
                                            v-for="(request, index) in requestsPendingPastor"
                                            :key="index"
                                            @click="goDepartmentRequest(request.id)"
                                            :class="{
                                                'table-secondary':
                                                    request.status_response_pastor == 'PENDING' &&
                                                    request.status_response_leader == 'PENDING',
                                                'table-info':
                                                    request.status_response_pastor == 'APPROVED' &&
                                                    request.status_response_leader == 'PENDING',
                                                'table-danger':
                                                    request.status_response_pastor ==
                                                    'NOT_APPROVED',
                                            }"
                                        >
                                            <td class="text-center">
                                                {{ index + 1 }}
                                            </td>
                                            <td>
                                                {{ request.department.name }}
                                            </td>
                                            <td>
                                                <img
                                                    class="rounded-circle me-3"
                                                    width="30"
                                                    :src="request.member_photo"
                                                    alt=""
                                                />
                                                <span>
                                                    {{ request.member_name }}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr class="mb-0" />
                    </div>

                    <div class="col-md-12 mt-3">
                        <div class="search-form-card shadow-sm">
                            <div
                                class="card-header d-flex flex-wrap align-items-center justify-content-between bg-white p-3 border-bottom"
                            >
                                <span class="font-bold">Frequência nos Cultos - Média</span>
                                <div class="mt-3 mt-sm-0">
                                    <div>
                                        <select
                                            class="form-select"
                                            v-model="seacher.censu_month"
                                            aria-label="Default select example"
                                            @change="search()"
                                        >
                                            <option
                                                v-for="month in last12Months"
                                                :key="month.value"
                                                :value="month.value"
                                            >
                                                {{ month.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <BarChart
                                    :labels="averageBySchedule['labels']"
                                    :series="averageBySchedule['series']"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4 d-none d-sm-block">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <div class="search-form-card overflow-auto shadow-sm" id="last-sales">
                                <div
                                    class="card-header d-flex align-items-center justify-content-between bg-white p-3"
                                >
                                    <span class="font-bold">Últimos visitantes</span>
                                    <Link
                                        class="btn btn-success fs-12 font-semibold"
                                        href="/dashboard/visitors"
                                        >Ver todos</Link
                                    >
                                </div>
                                <hr class="my-0" />
                                <div class="py-0">
                                    <div class="table-responsive" v-if="lastVisitors?.data.length">
                                        <table
                                            class="table align-middle table-hover"
                                            id="table-home-visitors"
                                        >
                                            <thead>
                                                <tr>
                                                    <th scope="col">Nome</th>
                                                    <th scope="col">Programação</th>
                                                    <th scope="col">Crente</th>
                                                    <th scope="col">Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(member, index) in lastVisitors.data"
                                                    :key="index"
                                                    @click="goToVisitorPage(member.id)"
                                                    :class="{
                                                        'table-warning': member.is_event_today,
                                                    }"
                                                >
                                                    <td>
                                                        {{ member.member.clean_name }}
                                                    </td>
                                                    <td>
                                                        <span v-if="member.schedule_name">{{
                                                            member.schedule_name
                                                        }}</span>

                                                        <span
                                                            v-else
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="top"
                                                            data-bs-title="Dado do SGI antigo"
                                                        >
                                                            <font-awesome-icon
                                                                :icon="['fas', 'minus']"
                                                            />
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <Badge
                                                            :name="
                                                                member.member.believer
                                                                    ? 'YES'
                                                                    : 'NOT'
                                                            "
                                                        />
                                                    </td>
                                                    <td>
                                                        <span v-if="member.is_event_today">
                                                            <span class="badge text-bg-primary p-2"
                                                                >Hoje</span
                                                            >
                                                        </span>
                                                        <span v-else>
                                                            {{ member.created_at }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div v-else class="text-center p-4">
                                        Nenhum visitante encontrado
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-2 d-flex align-items-center">
                <div class="col-lg-6 col-12" v-if="waterBaptism">
                    <div class="row">
                        <WaterBaptismCard :data="[waterBaptism]" />
                    </div>
                </div>
                <div :class="{ 'col-lg-6': waterBaptism, 'col-lg-12': !waterBaptism }">
                    <div class="search-form-card overflow-auto" id="last-sales">
                        <div
                            class="card-header d-flex align-items-center justify-content-between bg-white p-3"
                        >
                            <span class="font-bold">Últimas conversões</span>
                            <Link
                                class="btn btn-success fs-12 font-semibold"
                                href="/dashboard/new-births"
                                >Ver todas</Link
                            >
                        </div>
                        <hr class="my-0" />
                        <div class="py-0">
                            <div class="table-responsive" v-if="lastNewBirths?.data.length">
                                <table class="table align-middle table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nome</th>
                                            <th scope="col">Programação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(member, index) in lastNewBirths.data"
                                            :key="index"
                                            @click="goToNewBirthPage(member.id)"
                                            :class="{
                                                'table-warning': member.is_event_today,
                                            }"
                                        >
                                            <td>
                                                {{ member.member.clean_name }}
                                            </td>
                                            <td>
                                                <span v-if="member.schedule_name">{{
                                                    member.schedule_name
                                                }}</span>

                                                <span
                                                    v-else
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    data-bs-title="Dado do SGI antigo"
                                                >
                                                    <font-awesome-icon :icon="['fas', 'minus']" />
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-else class="text-center p-4">Nenhuma conversão encontrada</div>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <div v-if="lastEvents?.data.length">
                <div class="row mb-3">
                    <div class="col d-flex justify-content-between align-items-center">
                        <h4 class="mb-0">Eventos recentes</h4>
                        <Link :href="`/dashboard/events`" class="btn btn-primary btn-add">
                            <font-awesome-icon class="me-2" :icon="['fas', 'bars-staggered']" />
                            Ver todos
                        </Link>
                    </div>
                </div>
                <div class="row">
                    <EventCard
                        v-for="(item, index) in lastEvents.data"
                        :key="index"
                        :event="item"
                    />
                </div>
            </div>

            <div class="row d-flex align-items-center">
                <div class="col-lg-6 mb-3 mb-lg-0">
                    <div class="search-form-card overflow-auto shadow-sm" id="last-sales">
                        <div
                            class="card-header d-flex align-items-center justify-content-between bg-white p-3"
                        >
                            <span class="font-bold">Aniversariantes</span>
                            <div class="d-flex">
                                <select
                                    class="form-select me-2"
                                    v-model="seacher.birthday_month"
                                    aria-label="Default select example"
                                    @change="search()"
                                >
                                    <option value="1">Jan</option>
                                    <option value="2">Fev</option>
                                    <option value="3">Mar</option>
                                    <option value="4">Abr</option>
                                    <option value="5">Mai</option>
                                    <option value="6">Jun</option>
                                    <option value="7">Jul</option>
                                    <option value="8">Ago</option>
                                    <option value="9">Set</option>
                                    <option value="10">Out</option>
                                    <option value="11">Nov</option>
                                    <option value="12">Dez</option>
                                </select>
                                <a
                                    class="btn btn-danger fw-bold fs-12"
                                    :href="`/dashboard/members/birthdays/export?month=${seacher.birthday_month}`"
                                >
                                    <font-awesome-icon :icon="['fa', 'file-pdf']" />
                                </a>
                            </div>
                        </div>
                        <div class="card-body">
                            <hr class="my-0" />
                            <div class="py-0">
                                <div class="table-responsive" v-if="birthdaysMonth.length">
                                    <table class="table align-middle table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nome do membro</th>
                                                <th class="text-center" scope="col">Data</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(member, index) in birthdaysMonth"
                                                :key="index"
                                                @click="goToMemberPage(member.id)"
                                                :class="{
                                                    'table-warning': member.is_event_today,
                                                }"
                                            >
                                                <td>
                                                    <img
                                                        class="rounded-circle me-3"
                                                        width="30"
                                                        :src="member.identification_photo"
                                                        alt=""
                                                    />
                                                    <span>
                                                        {{ member.clean_name }}
                                                    </span>
                                                </td>
                                                <td class="text-center">
                                                    {{ formatDate(member.birth_date) }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-else class="text-center p-4">
                                    Nenhum aniversariante encontrado
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12 px-4">
                    <div class="row">
                        <div class="card mb-3 shadow-sm">
                            <div class="card-body">
                                <h5 class="card-title font-bold">Ministros</h5>
                                <p class="card-text font-regular">
                                    Veja os minitros da nossa igreja e suas ultimas atividades
                                </p>
                                <Link
                                    href="/dashboard/ministers"
                                    class="btn btn-success btn-sm text-white font-bold"
                                    >Ver ministros</Link
                                >
                            </div>
                        </div>

                        <div class="card shadow-sm">
                            <div class="card-body">
                                <h5 class="card-title font-bold">Dirigentes</h5>
                                <p class="card-text font-regular">
                                    Veja os dirigentes da nossa igreja e os últimos eventos que eles
                                    dirigiram
                                </p>
                                <Link
                                    href="/dashboard/leaders"
                                    class="btn btn-primary btn-sm text-white font-bold"
                                >
                                    Ver dirigentes</Link
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
