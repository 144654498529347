<script setup>
import { ref } from 'vue';
import { useForm, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    room: Object,
});

const form = useForm({
    identifier: props.room.identifier,
    description: props.room.description,
});

const update = () => form.put(`/dashboard/rooms/${props.room.id}`);
</script>

<template>
    <Dashboard page-title="Editar sala">
        <Breadcrumb
            current-page-name="Editar sala"
            :link-list="[
                {
                    previous_page_url: 'rooms',
                    previous_page_name: 'Salas',
                },
            ]"
        />
        <div id="create-room">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">Editar dados</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="`/dashboard/rooms/${room.id}`"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                </div>
            </div>

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-12">
                    <label for="identifier" class="form-label"> Nome da sala </label>

                    <input
                        id="identifier"
                        v-model="form.identifier"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Verbo da Vida Paulo Afonso"
                        required
                    />
                    <small v-if="form.errors.identifier" class="text-danger mt-3">
                        {{ form.errors.identifier }}
                    </small>
                </div>

                <div class="col-md-12">
                    <label for="description" class="form-label"> Descrição </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="description"
                        v-model="form.description"
                        type="text"
                        class="form-control"
                        name="description"
                        placeholder="Forneça alguma descrição para essa sala"
                        rows="5"
                    />

                    <small v-if="form.errors.description" class="text-danger mt-3">
                        {{ form.errors.description }}
                    </small>
                </div>
            </Form>
        </div>
    </Dashboard>
</template>
