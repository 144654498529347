<script setup>
import { computed } from 'vue';
import { router, Link } from '@inertiajs/vue3';

import Layout from '../Layout.vue';

const props = defineProps({
    classe: Object,
    lessons: Object,
});

const goToLessonPage = (lessonID) =>
    router.get(`/dashboard/child-department-classes/${props.classe.id}/lessons/${lessonID}`);
</script>

<template>
    <Layout :classe="classe">
        <div v-if="lessons.length">
            <div class="d-flex justify-content-between mb-2 align-items-center">
                <h5 class="font-bold mb-0">Aulas dessa turma</h5>
                <Link
                    class="btn btn-primary btn-redirection"
                    :href="`/dashboard/child-department-classes/${classe.id}/lessons/create`"
                >
                    Nova
                </Link>
            </div>
            <hr />
            <div class="table-responsive search-form-card p-0">
                <table class="table m-0 align-middle table-hover" id="table-families">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">#</th>
                            <th scope="col">Evento relacionado</th>
                            <th class="text-center" scope="col">Data</th>
                            <th class="text-center" scope="col">Professores</th>
                            <th class="text-center" scope="col">Presentes</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="(lesson, index) in lessons"
                            :key="index"
                            @click="goToLessonPage(lesson.id)"
                        >
                            <td class="text-center">
                                {{ index + 1 }}
                            </td>
                            <td>
                                <img
                                    height="35"
                                    width="35"
                                    class="rounded-circle border border-1 border-ligth"
                                    :src="`/storage/${lesson.event_photo}`"
                                    alt=""
                                />
                                <span class="ms-3">{{ lesson.event_name }}</span>
                            </td>
                            <td class="text-center">
                                {{ lesson.event_date }}
                            </td>

                            <td class="text-center">
                                <div v-if="lesson.teachers.length">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div v-for="(item, index) in lesson.teachers" :key="index">
                                            <img
                                                class="rounded-circle mx-2"
                                                width="30"
                                                :src="`${item.member.identification_photo}`"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>

                                <span
                                    v-else
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-title="Nenhum professor adicionado ainda"
                                >
                                    <font-awesome-icon :icon="['fas', 'minus']" />
                                </span>
                            </td>

                            <td class="text-center">{{ lesson.visiting_children }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-else>
            <p
                class="p-3 bg-info fs-14 bg-opacity-10 rounded-end font-semibold border-primary border-start border-5"
            >
                Nenhuma aula criada nessa turma ainda
            </p>

            <div class="d-flex justify-content-end">
                <Link
                    class="btn btn-primary btn-redirection"
                    :href="`/dashboard/child-department-classes/${classe.id}/lessons/create`"
                >
                    Adicionar
                </Link>
            </div>
        </div>
    </Layout>
</template>
