<script setup>
import { ref, reactive, onMounted } from 'vue';
import { useForm, router } from '@inertiajs/vue3';
import { vMaska } from 'maska/vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Shared/PhotoEditModal.vue';

const props = defineProps({
    sexs: Object,
    families: Object,
    classes: Object,
    types: Object,
});

const form = useForm({
    name: null,
    classe_id: null,
    photo_identification: '/img/avatars/male.png',
    sex: props.sexs[0].value,
    type: props.types[0].value,
    birth_date: null,
    family: null,
    observations: null,
    intolerant_foods: null,
    special_needs: props.special_needs,
});

const inputRef = ref(null);

const store = () => form.post('/dashboard/children');

const handleImageResized = (blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        form.photo_identification = e.target.result;
    };
    reader.readAsDataURL(blob);
};

const search = () => {
    router.visit('/dashboard/children/create', {
        method: 'get',
        data: { seacher },
        only: ['families'],
        preserveState: true,
        preserveScroll: true,
    });
};

const seacher = reactive({
    family_name: null,
});

let timeoutId = null;

const compositionSeacher = (field, event) => {
    seacher[field] = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
        search();
    }, 1000);
};

const toggleSelectionFamily = (id) => {
    if (form.family === id) {
        form.family = null;
    } else {
        form.family = id;
    }
};

const clearQueryParams = () => router.replace('/dashboard/children/create');

onMounted(() => {
    clearQueryParams();
});

const toggleClasseSelection = (classeId) => {
    form.classe_id = form.classe_id === classeId ? null : classeId;
};
</script>

<template>
    <Dashboard page-title="Nova criança">
        <Breadcrumb
            current-page-name="Nova criança"
            :link-list="[
                {
                    previous_page_url: 'children',
                    previous_page_name: 'Crianças',
                },
            ]"
        />
        <div id="create-minister">
            <h4 class="my-3">Nova criança</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing || !form.classe_id"
            >
                <div class="d-flex justify-content-center">
                    <div class="position-relative">
                        <img
                            :src="form.photo_identification"
                            alt="Identification Photo"
                            class="upload-box img-fluid shadow-sm"
                        />
                        <div class="position-absolute bottom-0 end-0 m-3">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#photoUpdateModal"
                                class="btn btn-edit shadow"
                            >
                                <font-awesome-icon
                                    class="text-white"
                                    :icon="['fas', 'pen-to-square']"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div
                    class="alert alert-danger mx-2"
                    role="alert"
                    v-if="form.errors.photo_identification"
                >
                    {{ form.errors.photo_identification }}
                </div>

                <div class="col-md-8">
                    <label for="name" class="form-label"> Nome do Criança </label>

                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Laura Vitória"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="col-md-4">
                    <label for="birth_date" class="form-label">Idade da criança</label>
                    <input
                        v-model="form.birth_date"
                        type="date"
                        class="form-control"
                        name="birth_date"
                        id="birth_date"
                        autocomplete="on"
                        aria-describedby="birthDateHelp"
                        required
                    />
                    <small v-if="form.errors.birth_date" class="text-danger mt-3">
                        {{ form.errors.birth_date }}
                    </small>
                </div>

                <div class="col-md-6">
                    <label for="sex" class="form-label">Sexo</label>
                    <select
                        id="sex"
                        v-model="form.sex"
                        class="form-select form-select"
                        aria-label="Small select example"
                    >
                        <option v-for="(item, index) in sexs" :key="index" :value="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-6">
                    <label for="type" class="form-label">Tipo de criança</label>
                    <select
                        id="type"
                        v-model="form.type"
                        class="form-select form-select"
                        aria-label="Small select example"
                    >
                        <option v-for="(item, index) in types" :key="index" :value="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-12">
                    <label for="observations" class="form-label"> Observações </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="observations"
                        v-model="form.observations"
                        type="text"
                        class="form-control"
                        name="observations"
                        placeholder="Forneça alguma observação"
                        rows="5"
                    />

                    <small v-if="form.errors.observations" class="text-danger mt-3">
                        {{ form.errors.observations }}
                    </small>
                </div>

                <div class="col-md-12">
                    <label for="intolerant_foods" class="form-label">
                        Alimentos de intolerância
                    </label>
                    <small class="optional ms-2">(opcional)</small>

                    <input
                        id="intolerant_foods"
                        v-model="form.intolerant_foods"
                        type="text"
                        class="form-control"
                        name="intolerant_foods"
                    />

                    <small v-if="form.errors.intolerant_foods" class="text-danger mt-3">
                        {{ form.errors.intolerant_foods }}
                    </small>
                </div>

                <div class="col-md-12">
                    <label for="special_needs" class="form-label"> Necessidades especiais </label>
                    <small class="optional ms-2">(opcional)</small>

                    <input
                        id="special_needs"
                        v-model="form.special_needs"
                        type="text"
                        class="form-control"
                        name="special_needs"
                    />

                    <small v-if="form.errors.special_needs" class="text-danger mt-3">
                        {{ form.errors.special_needs }}
                    </small>
                </div>

                <input type="file" ref="inputRef" class="file-input d-none" />

                <hr class="mb-0" />

                <div class="col-md-12 mb-3">
                    <label for="name" class="form-label">Nome da família</label>
                    <input
                        id="name"
                        v-model="seacher.family_name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="João Pedro de Souza"
                        @input="compositionSeacher('family_name', $event)"
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <ul class="list-group member-found mt-2 border-0" v-if="families.length">
                    <li
                        v-for="(item, index) in families"
                        :key="index"
                        class="list-group-item p-2 d-flex align-items-center mb-3"
                        :class="{ checked: form.family === item.id }"
                    >
                        <input
                            :id="'checkBoxVisitor' + item.id"
                            class="form-check-input me-1 mb-0"
                            type="checkbox"
                            name="listGroupRadio"
                            :value="item.id"
                            :checked="form.family === item.id"
                            @change="toggleSelectionFamily(item.id)"
                        />
                        <label
                            class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                            :for="'checkBoxVisitor' + item.id"
                            style="cursor: pointer"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ item.name }}</span>
                                <a
                                    :href="`/dashboard/families/${item.id}`"
                                    class="btn btn-primary btn-add z-2 me-3"
                                    target="_blank"
                                    >Acessar família</a
                                >
                            </div>
                        </label>
                    </li>
                </ul>

                <hr class="mb-0" />

                <legend class="mb-4">Selecione turma padrão da criança</legend>

                <div class="row">
                    <div
                        v-for="(item, index) in classes"
                        :key="index"
                        class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                    >
                        <label class="image-checkbox position-relative">
                            <img
                                class="img-responsive"
                                :src="`/storage/${item.banner}`"
                                :class="{
                                    selected: form.classe_id == item.id,
                                }"
                                @click="toggleClasseSelection(item.id)"
                            />
                            <span
                                v-show="form.classe_id == item.id"
                                class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                            >
                                <font-awesome-icon :icon="['fas', 'circle-check']" />
                            </span>
                            <span class="mt-3 d-block">{{ item.name }}</span>
                            <input type="checkbox" name="image[]" value="" />
                        </label>
                    </div>
                </div>
            </Form>

            <PhotoEditModal
                v-if="form.photo_identification"
                title="Adicionar foto"
                id="photoUpdateModal"
                @image-resized="handleImageResized"
            />
        </div>
    </Dashboard>
</template>
