<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

const props = defineProps({
    children: Object,
    sexs: Object,
    types: Object,
});

const goToChildPage = (childId) => router.get(`/dashboard/children/${childId}`);

const searchForm = reactive({
    name: null,
    type: 0,
    sex: 0,
});

const seacher = () =>
    router.get('/dashboard/children', searchForm, {
        preserveState: true,
    });

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const clearQueryParams = () => router.replace('/dashboard/children');

onMounted(() => {
    clearQueryParams();
});
</script>

<template>
    <Dashboard page-title="Crianças">
        <Breadcrumb current-page-name="Crianças" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Crianças</h4>
                <Link :href="`/dashboard/children/create`" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Novo
                </Link>
            </div>
        </div>

        <div class="row p-2">
            <div class="p-3 search-form-card">
                <div class="row d-flex justify-content-between align-items-center mt-2 px-3">
                    <div class="font-bold col-12 col-sm-6">Baixar crianças</div>
                    <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                        <a
                            class="btn btn-danger fw-bold fs-12"
                            :href="`/dashboard/children/pdf/export?name=${searchForm.name}&type=${searchForm.type}&sex=${searchForm.sex}`"
                        >
                            <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                        </a>
                        <a
                            class="btn btn-success ms-2 fw-bold fs-12"
                            :href="`/dashboard/children/excel/export?name=${searchForm.name}&type=${searchForm.type}&sex=${searchForm.sex}`"
                        >
                            <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" /> Excel
                        </a>
                    </div>
                </div>
                <hr class="mx-3" />
                <div class="px-3">
                    <div class="row g-3">
                        <div class="col-md-6">
                            <label class="form-label" for="floatingInput">Nome da criança</label>
                            <input
                                id="floatingInput"
                                type="email"
                                v-model="searchForm.name"
                                class="form-control"
                                :value="` `"
                                placeholder="Laura Vitória"
                                @input="compositionSeacher('name', $event)"
                            />
                        </div>

                        <div class="col-6 col-md-3">
                            <label for="sex" class="form-label">Sexo</label>
                            <select
                                id="sex"
                                v-model="searchForm.sex"
                                class="form-select form-select"
                                aria-label="Small select example"
                                @change="seacher()"
                            >
                                <option value="0">Todos os sexos</option>
                                <option
                                    v-for="(item, index) in sexs"
                                    :key="index"
                                    :value="item.value"
                                >
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>

                        <div class="col-6 col-md-3">
                            <label for="type" class="form-label">Tipo de criança</label>
                            <select
                                id="type"
                                v-model="searchForm.type"
                                class="form-select form-select"
                                aria-label="Small select example"
                                @change="seacher()"
                            >
                                <option value="0">Todos os tipos</option>
                                <option
                                    v-for="(item, index) in types"
                                    :key="index"
                                    :value="item.value"
                                >
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row px-4 mt-3">
                    <hr />
                    <div class="table-responsive">
                        <table class="table align-middle table-hover" id="table-children">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">#</th>
                                    <th scope="col">Nome da criança</th>
                                    <th class="text-center" scope="col">Tipo</th>
                                    <th class="text-center" scope="col">Idade</th>
                                    <th class="text-center" scope="col">Parentes</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(child, index) in children.data"
                                    :key="index"
                                    @click="goToChildPage(child.id)"
                                >
                                    <td class="text-center">
                                        {{ index + 1 }}
                                    </td>
                                    <td>
                                        <img
                                            class="rounded-circle me-3"
                                            width="30"
                                            :src="child.photo_identification"
                                            alt=""
                                        />
                                        <span>
                                            {{ child.name }}
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <Badge :name="child.type" />
                                    </td>
                                    <td class="text-center">
                                        {{ child.age }}
                                    </td>
                                    <td class="text-center">
                                        <div v-if="child.family?.parents.length">
                                            <div
                                                class="d-flex justify-content-center align-items-center"
                                            >
                                                <div
                                                    v-for="(item, index) in child.family.parents"
                                                    :key="index"
                                                >
                                                    <img
                                                        class="rounded-circle mx-2"
                                                        width="30"
                                                        :src="`${item.profile_photo_url}`"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <span
                                            v-else
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-title="Não vinculado a nenhuma família"
                                        >
                                            <font-awesome-icon :icon="['fas', 'minus']" />
                                        </span>
                                    </td>
                                </tr>
                            </tbody>

                            <tfoot class="bg-white">
                                <tr>
                                    <td colspan="5">
                                        <Pagination
                                            :data="children.meta"
                                            reference-date="children"
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
