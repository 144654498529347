<script setup>
import { defineProps, reactive, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

const props = defineProps({
    lessons: Object,
    classes: Object,
});

const goToChildLessonPage = (classeID, lessonID) =>
    router.get(`/dashboard/child-department-classes/${classeID}/lessons/${lessonID}`);

const clearQueryParams = () => router.replace('/dashboard/child-lessons');

const searchForm = reactive({
    classe_id: 0,
});

const seacher = () => router.get('/dashboard/child-lessons', searchForm, { preserveState: true });

onMounted(() => {
    clearQueryParams();
});
</script>

<template>
    <Dashboard page-title="Recepção Infantil">
        <Breadcrumb current-page-name="Recepção Infantil" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Recepção Infantil</h4>
                <Link :href="`/dashboard/child-lessons/create`" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Nova
                </Link>
            </div>
        </div>

        <div class="row p-2">
            <div class="p-3 search-form-card">
                <div class="row px-3 g-3">
                    <div class="col-md-12">
                        <label for="class_id" class="form-label">Turma</label>
                        <select
                            id="class_id"
                            v-model="searchForm.classe_id"
                            class="form-select form-select"
                            aria-label="Small select example"
                            @change="seacher()"
                        >
                            <option value="0">Todas</option>
                            <option v-for="(item, index) in classes" :key="index" :value="item.id">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="row p-3">
                    <div class="table-responsive">
                        <table class="table m-0 align-middle table-hover" id="table-child-lesson">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">#</th>
                                    <th scope="col">Criança</th>
                                    <th scope="col">Turma selecionada</th>
                                    <th scope="col">Evento relacionado</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(lesson, index) in lessons"
                                    :key="index"
                                    @click="
                                        goToChildLessonPage(
                                            lesson.classe.id,
                                            lesson.child_department_lesson_id
                                        )
                                    "
                                >
                                    <td class="text-center">
                                        {{ index + 1 }}
                                    </td>
                                    <td>
                                        <img
                                            class="rounded-circle me-3"
                                            width="30"
                                            :src="lesson.child.photo_identification"
                                            alt=""
                                        />
                                        <span>
                                            {{ lesson.child.clean_name }}
                                        </span>
                                    </td>
                                    <td>
                                        <img
                                            class="rounded-circle me-3"
                                            width="30"
                                            height="30"
                                            :src="`/storage/${lesson.classe.banner}`"
                                            alt=""
                                        />
                                        <span>
                                            {{ lesson.classe.name }}
                                        </span>
                                    </td>
                                    <td>
                                        <img
                                            class="rounded-circle me-3"
                                            width="30"
                                            height="30"
                                            :src="`/storage/${lesson.event_banner}`"
                                            alt=""
                                        />
                                        <span>
                                            {{ lesson.event_name }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
