<script setup>
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

const props = defineProps({
    classe: Object,
    assignment: Object,
    lessons: Object,
});

const goToLessonPage = (lessonID) =>
    router.get(`/dashboard/child-department-classes/${props.classe.id}/lessons/${lessonID}`);
</script>

<template>
    <Dashboard :page-title="assignment.child.name">
        <Breadcrumb
            :current-page-name="assignment.child.name"
            :link-list="[
                {
                    previous_page_url: `child-department-classes/${assignment.classe.id}/children`,
                    previous_page_name: `Turma`,
                },
            ]"
        />

        <div class="d-flex justify-content-end container-buttons">
            <Link
                :href="`/dashboard/children/edit/${assignment.child.id}`"
                class="btn btn-add btn-primary"
            >
                <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                Editar dados
            </Link>
        </div>

        <div class="mb-3 mt-3 mt-sm-0">
            <div id="child-details" class="row mb-3 px-3">
                <div class="d-flex justify-content-center">
                    <Link :href="`/dashboard/children/${assignment.child.id}`">
                        <img
                            class="identification_photo shadow-sm"
                            :src="`${assignment.child.photo_identification}`"
                            alt=""
                        />
                    </Link>
                </div>
                <h4 class="text-center mt-4">
                    {{ assignment.child.name }}
                </h4>
            </div>
        </div>

        <div class="row p-3">
            <div class="table-responsive search-form-card p-0" v-if="lessons.length">
                <table class="table m-0 align-middle table-hover" id="table-children">
                    <thead class="table-light">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Evento relacionado</th>
                            <th class="text-center" scope="col">Data</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="(lesson, index) in lessons"
                            :key="index"
                            @click="goToLessonPage(lesson.child_department_lesson_id)"
                        >
                            <td>
                                {{ index + 1 }}
                            </td>

                            <td>
                                <Link :href="`/dashboard/events/${lesson.event_id}`">
                                    <img
                                        class="rounded-circle me-3"
                                        width="30"
                                        height="30"
                                        :src="`/storage/${lesson.event_banner}`"
                                        alt=""
                                    />
                                </Link>
                                <span>
                                    {{ lesson.event_name }}
                                </span>
                            </td>

                            <td class="text-center">
                                {{ lesson.event_date }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else>
                <p
                    class="p-3 bg-info fs-14 bg-opacity-10 rounded-end font-semibold border-primary border-start border-5"
                >
                    Essa criança ainda não participou de nenhuma aula dessa turma
                </p>
            </div>
        </div>
    </Dashboard>
</template>
