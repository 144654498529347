<script setup>
import { useForm, Link, router } from '@inertiajs/vue3';
import { ref, onMounted } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    lesson: Object,
    allTeachers: Object,
    linkedTeachers: Object,
});

const form = useForm({
    _method: 'put',
    teachers: [],
    support_material: null,
    active: props.lesson.active ? true : false,
});

const toggleTeacherSelection = (teacherID) => {
    const index = form.teachers.indexOf(teacherID);
    if (index === -1) {
        form.teachers.push(teacherID);
    } else {
        form.teachers.splice(index, 1);
    }
};

const lessonEditModalCloseButton = ref(null);

const destroy = () => {
    lessonEditModalCloseButton.value.click();
    router.delete(
        `/dashboard/child-department-classes/${props.lesson.child_department_class_id}/lessons/${props.lesson.id}`
    );
};

onMounted(() => {
    form.teachers = props.linkedTeachers.map((teacher) => teacher.member_child_department_id);
});

const update = () =>
    form.post(
        `/dashboard/child-department-classes/${props.lesson.child_department_class_id}/lessons/${props.lesson.id}`
    );

const handleFileUpload = (event) => {
    const file = event.target.files[0];
    form.support_material = file;
};
</script>

<template>
    <Dashboard page-title="Aula">
        <Breadcrumb
            current-page-name="Aula"
            :link-list="[
                {
                    previous_page_url: `child-department-classes/${lesson.child_department_class_id}/lessons/${lesson.id}`,
                    previous_page_name: 'Aula',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link
                    :href="`/dashboard/child-department-classes/${lesson.child_department_class_id}/lessons/${lesson.id}`"
                    class="btn btn-success btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#lessonEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Editar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-lg-12">
                <legend class="mb-4">Selecione os professores relacionados</legend>

                <div class="row">
                    <div
                        v-for="(item, index) in allTeachers"
                        :key="index"
                        class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                    >
                        <label class="image-checkbox position-relative">
                            <img
                                class="img-responsive"
                                :src="`${item.member.identification_photo}`"
                                :class="{
                                    selected: form.teachers.includes(item.id),
                                }"
                                @click="toggleTeacherSelection(item.id)"
                            />
                            <span
                                v-show="form.teachers.includes(item.id)"
                                class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                            >
                                <font-awesome-icon :icon="['fas', 'circle-check']" />
                            </span>
                            <span class="mt-3 d-block">{{ item.member.clean_name }}</span>
                            <input type="checkbox" name="teachers[]" value="" />
                        </label>
                    </div>
                </div>
            </div>

            <hr class="mb-0" />

            <legend>Material de apoio</legend>

            <div class="col-md-12">
                <label for="formFile" class="form-label"
                    >Selecione o material que será usado pelo ministro na aula</label
                >
                <input
                    class="form-control"
                    type="file"
                    id="formFile"
                    @change="handleFileUpload"
                    accept="application/pdf"
                />
            </div>

            <div class="col-md-12 m-0 p-0 mt-4">
                <div class="form-check d-flex align-items-center form-switch">
                    <input
                        id="flexCheckDefault"
                        v-model="form.active"
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        checked
                    />
                    <label class="form-check-label ms-3" for="flexCheckDefault">
                        Aula ativa?
                    </label>
                </div>
            </div>
        </Form>

        <ConfirmationModal
            modal_id="lessonEditModal"
            modal_title="Confirmação de remoção do registro"
        >
            <template v-slot:modal-text> Você tem certeza que deseja excluir o registro? </template>
            <template v-slot:modal-footer>
                <button
                    type="button"
                    ref="lessonEditModalCloseButton"
                    class="btn btn-secondary text-white"
                    data-bs-dismiss="modal"
                >
                    Voltar
                </button>
                <button @click="destroy" type="button" class="btn btn-danger text-white">
                    Deletar registro
                </button>
            </template>
        </ConfirmationModal>
    </Dashboard>
</template>
