<script setup>
import { reactive } from 'vue';
import { router, Link } from '@inertiajs/vue3';

import Layout from '../Layout.vue';

const props = defineProps({
    classe: Object,
    children: Object,
    types: Object,
    sexs: Object,
});

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

const goToChildPage = (childId) =>
    router.get(`/dashboard/child-department-classes/${props.classe.id}/children/${childId}`);

const searchForm = reactive({
    type: 'FIXED',
    sex: 0,
});

const seacher = () =>
    router.get(`/dashboard/child-department-classes/${props.classe.id}/children`, searchForm, {
        preserveState: true,
    });
</script>

<template>
    <Layout :classe="classe">
        <div v-if="children.length">
            <div class="d-flex justify-content-between mb-2 align-items-center">
                <h5 class="font-bold mb-0">Crianças nessa turma</h5>
                <Link
                    class="btn btn-primary btn-redirection"
                    :href="`/dashboard/child-department-classes/${classe.id}/children/create`"
                >
                    Nova
                </Link>
            </div>

            <hr />

            <div class="p-3 search-form-card">
                <div class="row">
                    <div class="col-md-6">
                        <label for="sex" class="form-label">Sexo</label>
                        <select
                            id="sex"
                            v-model="searchForm.sex"
                            class="form-select form-select"
                            aria-label="Small select example"
                            @change="seacher()"
                        >
                            <option value="0">Todos os sexos</option>
                            <option v-for="(item, index) in sexs" :key="index" :value="item.value">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-6 mt-2 mt-sm-0">
                        <label for="type" class="form-label">Tipo de criança</label>
                        <select
                            id="type"
                            v-model="searchForm.type"
                            class="form-select form-select"
                            aria-label="Small select example"
                            @change="seacher()"
                        >
                            <option value="0">Todos os tipos</option>
                            <option v-for="(item, index) in types" :key="index" :value="item.value">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="table-responsive search-form-card p-0 mt-3">
                    <table class="table m-0 align-middle table-hover" id="table-families">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">#</th>
                                <th scope="col">Nome da criança</th>
                                <th class="text-center" scope="col">Idade</th>
                                <th class="text-center" scope="col">Tipo</th>
                                <th class="text-center" scope="col">Família</th>
                                <th class="text-center" scope="col">A.I</th>
                                <th class="text-center" scope="col">N.E</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="(child, index) in children"
                                :key="index"
                                @click="goToChildPage(child.id)"
                            >
                                <td class="text-center">
                                    {{ index + 1 }}
                                </td>
                                <td>
                                    <img
                                        class="rounded-circle me-3"
                                        width="30"
                                        :src="`${child.child.photo_identification}`"
                                        alt=""
                                    />
                                    <span>
                                        {{ child.child.clean_name }}
                                    </span>
                                </td>

                                <td class="text-center">
                                    {{ child.child.age }}
                                </td>

                                <td class="text-center">
                                    <Badge :name="child.child.type" />
                                </td>

                                <td class="text-center">
                                    <div v-if="child.child.family?.parents.length">
                                        <div
                                            class="d-flex justify-content-center align-items-center"
                                        >
                                            <div
                                                v-for="(item, index) in child.child.family.parents"
                                                :key="index"
                                            >
                                                <img
                                                    class="rounded-circle mx-2"
                                                    width="30"
                                                    :src="`${item.profile_photo_url}`"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <span
                                        v-else
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Não vinculado a nenhuma família"
                                    >
                                        <font-awesome-icon :icon="['fas', 'minus']" />
                                    </span>
                                </td>

                                <td class="text-center">
                                    <span
                                        v-if="child.child.intolerant_foods"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        :data-bs-title="
                                            'Intolerâncias: ' + child.child.intolerant_foods
                                        "
                                    >
                                        <font-awesome-icon
                                            class="text-danger"
                                            :icon="['fas', 'wheat-awn-circle-exclamation']"
                                        />
                                    </span>
                                    <span
                                        v-else
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Não intolerante a nenhum alimento"
                                    >
                                        <font-awesome-icon :icon="['fas', 'minus']" />
                                    </span>
                                </td>

                                <td class="text-center">
                                    <span
                                        v-if="child.child.special_needs"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        :data-bs-title="
                                            'Necessidades Especiais: ' + child.child.special_needs
                                        "
                                    >
                                        <font-awesome-icon
                                            class="text-warning"
                                            :icon="['fas', 'star']"
                                        />
                                    </span>
                                    <span
                                        v-else
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Não possui nenhuma necessidade especial"
                                    >
                                        <font-awesome-icon :icon="['fas', 'minus']" />
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div v-else>
            <p
                class="p-3 bg-info fs-14 bg-opacity-10 rounded-end font-semibold border-primary border-start border-5"
            >
                Nenhua criança faz parte dessa turma ainda
            </p>

            <div class="d-flex justify-content-end">
                <Link
                    class="btn btn-primary btn-redirection"
                    :href="`/dashboard/child-department-classes/${classe.id}/children/create`"
                >
                    Adicionar
                </Link>
            </div>
        </div>
    </Layout>
</template>
