<script setup>
import { defineProps, ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';

const props = defineProps({
    lesson: Object,
});

const form = useForm({
    visitor: props.lesson.visitor ? true : false,
    new_birth: props.lesson.new_birth ? true : false,
});

const update = () => form.put(`/dashboard/child-lessons/${props.lesson.id}`);

const lessonEditModalCloseButton = ref(null);

const destroy = () => {
    lessonEditModalCloseButton.value.click();
    router.delete(`/dashboard/child-lessons/${props.lesson.id}`);
};
</script>

<template>
    <Dashboard page-title="Recepção Infantil">
        <Breadcrumb
            current-page-name="Recepção Infantil"
            :link-list="[
                {
                    previous_page_url: `child-lessons`,
                    previous_page_name: 'Crianças',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>

            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link
                    :href="`/dashboard/child-lessons/${lesson.id}`"
                    class="btn btn-success btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>

                <a
                    data-bs-toggle="modal"
                    data-bs-target="#lessonEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Editar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-md-12 m-0 p-0 mt-4">
                <div class="form-check d-flex align-items-center form-switch">
                    <input
                        id="flexCheckDefault"
                        v-model="form.visitor"
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        checked
                    />
                    <label class="form-check-label ms-3" for="flexCheckDefault">
                        Primeira visita da criança?
                    </label>
                </div>
            </div>

            <div class="col-md-12 m-0 p-0 mt-4">
                <div class="form-check d-flex align-items-center form-switch">
                    <input
                        id="flexCheckDefault"
                        v-model="form.new_birth"
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        checked
                    />
                    <label class="form-check-label ms-3" for="flexCheckDefault">
                        Essa criança aceitou Jesus nessa aula?
                    </label>
                </div>
            </div>

            <ConfirmationModal
                modal_id="lessonEditModal"
                modal_title="Confirmação de remoção do registro"
            >
                <template v-slot:modal-text>
                    Você tem certeza que deseja excluir o registro?
                </template>
                <template v-slot:modal-footer>
                    <button
                        type="button"
                        ref="lessonEditModalCloseButton"
                        class="btn btn-secondary text-white"
                        data-bs-dismiss="modal"
                    >
                        Voltar
                    </button>
                    <button @click="destroy" type="button" class="btn btn-danger text-white">
                        Deletar registro
                    </button>
                </template>
            </ConfirmationModal>
        </Form>
    </Dashboard>
</template>
