<script setup>
import { router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import EventCardMember from '@/Pages/Dashboard/Pages/Events/Components/EventCardMember.vue';
import CreatorRecord from '@/Pages/Dashboard/Shared/CreatorRecord.vue';

const props = defineProps({
    planning: Object,
    event: Object,
    creatorRegistration: Object,
});

const goToMusicPage = (musicId) => router.get(`/dashboard/musics/${musicId}`);
</script>

<template>
    <Dashboard page-title="Planejamento">
        <Breadcrumb
            current-page-name="Planejamento"
            :link-list="[
                { previous_page_url: `musical-plannings`, previous_page_name: 'Planejamentos' },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Planejamento Musical</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link
                    :href="`/dashboard/musical-plannings/edit/${planning.id}`"
                    class="btn btn-primary btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                    Editar dados
                </Link>
            </div>
        </div>

        <EventCardMember :event="event" v-if="event" />

        <hr />

        <template v-if="planning.colors.length">
            <h4 class="font-bold font-bold mb-0">Paleta de cores</h4>
            <span class="text-secondary font-semibold fs-12 mb-3">
                Que serão usadas pela equipe
            </span>
            <div class="d-flex justify-content-center mt-4 mt-sm-0">
                <div
                    class="text-center d-flex flex-column align-items-center me-5"
                    v-for="(item, index) in planning.colors"
                    :key="index"
                >
                    <div
                        :style="{
                            width: '50px',
                            height: '50px',
                            backgroundColor: `${item.color}`,
                            borderRadius: '50%',
                        }"
                        class="border border-3 border-info"
                    ></div>
                    <span class="mt-2 font-semibold">{{ item.name }}</span>
                </div>
            </div>
            <hr />
        </template>

        <template v-if="planning.musics.length">
            <h4 class="font-bold font-bold mb-0">Músicas</h4>
            <span class="text-secondary font-semibold fs-12 mb-3">Relacionadas a esse evento</span>
            <div class="table-responsive search-form-card mt-3">
                <table class="table m-0 align-middle table-hover" id="table-musics">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">#</th>
                            <th scope="col">Nome</th>
                            <th class="text-center" scope="col">Youtube</th>
                            <th class="text-center" scope="col">Duração</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="(music, musicIndex) in planning.musics"
                            :key="musicIndex"
                            @click="goToMusicPage(music.id)"
                        >
                            <td class="text-center">
                                {{ musicIndex + 1 }}
                            </td>
                            <td>
                                <img
                                    class="rounded-pill me-3"
                                    height="35"
                                    width="35"
                                    :src="`/storage/${music.cover_photo}`"
                                    alt=""
                                />
                                <span>
                                    {{ music.name }}
                                </span>
                            </td>

                            <td class="text-center">
                                <a
                                    target="_blank"
                                    class="btn btn-link text-danger p-0 fs-3"
                                    :class="{ disabled: !music.youtube_link }"
                                    :href="music.youtube_link"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    data-bs-title="Assistir no Youtube"
                                >
                                    <font-awesome-icon :icon="['fab', 'youtube']" />
                                </a>
                            </td>

                            <td class="text-center">{{ music.duration }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>

        <hr />
        <template v-if="planning.observations">
            <h4 class="font-bold font-bold mb-0 mt-3">Observações</h4>
            <span class="text-secondary font-semibold fs-12 mb-3"
                >Relacionadas a esse planejamento</span
            >
            <p class="p-3 bg-info bg-opacity-10 border border-info my-3">
                {{ planning.observations }}
            </p>
        </template>
        <CreatorRecord
            v-if="creatorRegistration.name"
            :data="creatorRegistration"
            title="Plano musical cadastrado por: "
        />
    </Dashboard>
</template>
