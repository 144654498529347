<script setup>
import { useForm, Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

const props = defineProps({
    lesson: Object,
    children: Object,
    teachers: Object,
});

const goToChildPage = (childID) => router.get(`/dashboard/child-lessons/${childID}`);
</script>

<template>
    <Dashboard page-title="Aula">
        <Breadcrumb
            current-page-name="Aula"
            :link-list="[
                {
                    previous_page_url: `child-department-classes/${lesson.child_department_class_id}/lessons`,
                    previous_page_name: 'Aulas',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Evento relacionado</h4>
            </div>
            <div class="col-md-6 text-end mt-2 mt-sm-0">
                <Link
                    :href="`/dashboard/child-department-classes/${lesson.child_department_class_id}/lessons/edit/${lesson.id}`"
                    class="btn btn-add btn-primary"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                    Editar dados
                </Link>
            </div>
        </div>

        <div v-if="lesson.active" class="alert mx-0 font-semibold fs-12 alert-success" role="alert">
            Essa aula esta ativa
        </div>

        <div class="card p-4">
            <h6 class="font-bold">Professores dessa aula</h6>

            <div class="row" v-if="teachers.length">
                <div
                    v-for="(counselor, index) in teachers"
                    :key="index"
                    class="col-md-2 col-6 counselors mt-3"
                >
                    <Link
                        class="d-block text-center"
                        :href="`/dashboard/counselors/${counselor.id}`"
                    >
                        <img
                            class="image-counselor border border-info border-3"
                            :src="`${counselor.member.identification_photo}`"
                            alt=""
                        />
                    </Link>
                    <span class="name d-block text-center mt-2">
                        {{ counselor.member.clean_name }}
                    </span>
                </div>
            </div>

            <div v-else>Nenhum professor adicionado ainda</div>
        </div>

        <div class="card p-4 mt-4">
            <div class="d-flex position-relative">
                <img
                    :src="`/storage/${lesson.event_photo}`"
                    class="flex-shrink-0 me-3 rounded-3 shadow-sm bg-secondary"
                    style="height: 125px; width: 108px"
                />
                <div>
                    <h5 class="mt-0 font-bold">
                        {{ lesson.event_name }}:
                        {{ lesson.event_date }}
                    </h5>

                    <p>Este é o evento que está acontecendo nesse momento</p>

                    <Link
                        :href="`/dashboard/events/${lesson.event_id}`"
                        class="btn btn-success btn-add"
                        >Acesar evento</Link
                    >
                </div>
            </div>
        </div>

        <div class="card p-4 mt-4">
            <div class="d-flex justify-content-between align-items-center">
                <h5 class="font-bold m-0">Material de Apoio</h5>
                <a
                    v-if="lesson.support_material"
                    :href="`/storage/${lesson.support_material}`"
                    download
                    class="btn btn-danger font-bold fs-12"
                >
                    <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                </a>
            </div>

            <hr />

            <div v-if="lesson.support_material">
                <iframe
                    :src="`/storage/${lesson.support_material}`"
                    width="100%"
                    height="400px"
                    class="border rounded shadow-sm"
                ></iframe>
            </div>

            <div v-else>
                <p class="text-muted">Nenhum material de apoio disponível para esta aula.</p>
            </div>
        </div>

        <template v-if="children.length">
            <h5 class="my-3 font-bold">{{ children.length }} Criança(s) presente(s) nessa aula</h5>

            <div class="p-3 search-form-card mt-4">
                <div class="row p-3">
                    <div class="table-responsive">
                        <table class="table m-0 align-middle table-hover" id="table-children">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">#</th>
                                    <th scope="col">Criança</th>
                                    <th class="text-center" scope="col">Família</th>
                                    <th class="text-center" scope="col">Visitante</th>
                                    <th class="text-center" scope="col">A.I</th>
                                    <th class="text-center" scope="col">N.E</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(lesson, index) in children"
                                    :key="index"
                                    @click="goToChildPage(lesson.id)"
                                >
                                    <td class="text-center">
                                        {{ index + 1 }}
                                    </td>
                                    <td>
                                        <img
                                            class="rounded-circle me-3"
                                            width="30"
                                            :src="lesson.child.photo_identification"
                                            alt=""
                                        />
                                        <span>
                                            {{ lesson.child.clean_name }}
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <div v-if="lesson.child.family?.parents.length">
                                            <div
                                                class="d-flex justify-content-center align-items-center"
                                            >
                                                <div
                                                    v-for="(item, index) in lesson.child.family
                                                        .parents"
                                                    :key="index"
                                                >
                                                    <img
                                                        class="rounded-circle me-3"
                                                        width="30"
                                                        :src="`${item.profile_photo_url}`"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <span
                                            v-else
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-title="Não vinculado a nenhuma família"
                                        >
                                            <font-awesome-icon :icon="['fas', 'minus']" />
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <Badge :name="lesson.visitor ? 'YES' : 'NOT'" />
                                    </td>
                                    <td class="text-center">
                                        <span
                                            v-if="lesson.child.intolerant_foods"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            :data-bs-title="
                                                'Intolerâncias: ' + lesson.child.intolerant_foods
                                            "
                                        >
                                            <font-awesome-icon
                                                class="text-danger"
                                                :icon="['fas', 'wheat-awn-circle-exclamation']"
                                            />
                                        </span>
                                        <span
                                            v-else
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-title="Não intolerante a nenhum alimento"
                                        >
                                            <font-awesome-icon :icon="['fas', 'minus']" />
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <span
                                            v-if="lesson.child.special_needs"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            :data-bs-title="
                                                'Necessidades Especiais: ' +
                                                lesson.child.special_needs
                                            "
                                        >
                                            <font-awesome-icon
                                                class="text-warning"
                                                :icon="['fas', 'star']"
                                            />
                                        </span>
                                        <span
                                            v-else
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-title="Não possui nenhuma necessidade especial"
                                        >
                                            <font-awesome-icon :icon="['fas', 'minus']" />
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </template>
    </Dashboard>
</template>
