<script setup>
import { ref, reactive, onMounted } from 'vue';
import { useForm, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Shared/PhotoEditModal.vue';

const props = defineProps({
    department: Object,
});

const form = useForm({
    name: props.department.name,
    image_identification: `/storage/${props.department.image_identification}`,
    description: props.department.description,
    flexible_scheduling: props.department.flexible_scheduling ? true : false,
    identification_color: props.department.identification_color,
});

const inputRef = ref(null);

const handleImageResized = (blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        form.image_identification = e.target.result;
    };
    reader.readAsDataURL(blob);
};

const update = () => form.put(`/dashboard/departments/${props.department.id}`);
</script>

<template>
    <Dashboard page-title="Editar departamento">
        <Breadcrumb
            current-page-name="Editar departamento"
            :link-list="[
                {
                    previous_page_url: 'departments',
                    previous_page_name: 'Departamentos',
                },
            ]"
        />
        <div id="create-minister">
            <h4 class="my-3">Editar departamento</h4>

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="d-flex justify-content-center">
                    <div class="position-relative">
                        <img
                            :src="form.image_identification"
                            alt="Identification Photo"
                            class="upload-box img-fluid shadow-sm"
                        />
                        <div class="position-absolute bottom-0 end-0 m-3">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#photoUpdateModal"
                                class="btn btn-edit shadow"
                            >
                                <font-awesome-icon class="text-white" :icon="['fas', 'pen']" />
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <label for="name" class="form-label"> Nome do departamento </label>

                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Comunicação"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="col-md-6">
                    <label for="exampleColorInput" class="form-label">Cor de identificação</label>
                    <input
                        type="color"
                        v-model="form.identification_color"
                        class="form-control form-control-color"
                        id="exampleColorInput"
                        value="#563d7c"
                        title="Choose your color"
                    />
                </div>

                <div class="col-md-12">
                    <label for="description" class="form-label"> Descrição </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="description"
                        v-model="form.description"
                        type="text"
                        class="form-control"
                        name="description"
                        placeholder="Forneça alguma descrição para o departamento"
                        rows="5"
                    />

                    <small v-if="form.errors.description" class="text-danger mt-3">
                        {{ form.errors.description }}
                    </small>
                </div>

                <div class="col-md-12">
                    <div class="form-check form-switch d-flex align-items-center">
                        <input
                            class="form-check-input my-0"
                            v-model="form.flexible_scheduling"
                            type="checkbox"
                            role="switch"
                            id="flexible_scheduling"
                        />
                        <label class="form-check-label ms-3" for="flexible_scheduling"
                            >Escala Flexível ?</label
                        >
                    </div>
                </div>

                <input type="file" ref="inputRef" class="file-input d-none" />
            </Form>
            <PhotoEditModal
                v-if="form.image_identification"
                title="Adicionar foto"
                id="photoUpdateModal"
                @image-resized="handleImageResized"
            />
        </div>
    </Dashboard>
</template>
