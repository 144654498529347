<script setup>
import { defineProps } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

const props = defineProps({
    rooms: Object,
});

const goToRoomPage = (roomId) => router.get(`/dashboard/rooms/${roomId}`);
</script>

<template>
    <Dashboard page-title="Salas">
        <Breadcrumb current-page-name="Salas" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Salas</h4>
                <Link :href="`/dashboard/rooms/create`" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Nova
                </Link>
            </div>
        </div>

        <div class="p-3 search-form-card">
            <div class="row px-4 mt-3">
                <div class="table-responsive">
                    <table class="table align-middle table-hover" id="table-rooms">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">#</th>
                                <th scope="col">Nome de identificação</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="(room, index) in rooms"
                                :key="index"
                                @click="goToRoomPage(room.id)"
                            >
                                <td class="text-center">
                                    {{ index + 1 }}
                                </td>
                                <td>
                                    {{ room.identifier }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
